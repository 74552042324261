<template xmlns="http://www.w3.org/1999/html">
  <div>
    <BrochureHero
      :photos="heroSection.photos"
      :title="heroSection.title"
      :subtitle="heroSection.subtitle"
      :features="heroSection.features"
      :primaryButton="heroSection.primaryButton"
    ></BrochureHero>

    <BrochureComparison
      :title="comparisonSection.title"
      :table="comparisonSection.table"
    ></BrochureComparison>
    <BrochureProducts :products="productSection.products"></BrochureProducts>
    <BrouchureSteps :steps="stepsSection"></BrouchureSteps>
    <slot />
  </div>
</template>

<style scoped></style>

<script>
import BrochureHero from '@js/components/Brochure/BrochureHero.vue';
import Container from '@components/Core/Container.vue';
import Cta from '@components/Button/Cta.vue';
import ImageLazyLoad from '@components/ImageLazyLoad.vue';
import { ref } from 'vue';
import BrochureComparison from '@components/Brochure/BrochureComparison.vue';
import BrochureProducts from '@js/components/Brochure/BrochureProducts.vue';
import BrouchureSteps from '@components/Brochure/BrochureSteps.vue';

export default {
  name: 'WillWriting',

  components: {
    BrouchureSteps,
    BrochureProducts,
    BrochureComparison,
    BrochureHero,
    Container,
    Cta,
    ImageLazyLoad,
  },
  props: {
    utmParams: {
      type: String,
      default: '',
      required: false,
    },
  },

  setup(props) {
    const { utmParams } = props;

    const heroSection = ref({
      title: 'Write a will that’s tailored to you.',
      subtitle: 'How will you make it your own?',
      photos: [
        { name: 'water sacrifice', src: '/theme/ol/water-sacrifice.jpeg' },
        { name: 'fatherhood', src: '/theme/ol/fatherhood.jpeg' },
        { name: 'suz and grandad', src: '/theme/ol/Suz-great-grandad.jpeg' },
      ],
      features: [
        { svg: 'Time', text: 'Done in 15 minutes' },
        { svg: 'WhiteCheckmark', text: 'With expert support' },
        { svg: 'PoundSign', text: 'From £90' },
      ],
      primaryButton: {
        text: "Let's get started",
        route: route('will.typeform-onboarding', { utmParams }),
      },
    });
    const comparisonSection = ref({
      title: 'What are our different types of wills?',
      table: [
        {
          title: 'Basic Will',
          subheader: '£90',
          points: [
            'No advice needed',
            'Can be completed online or over the phone',
            'For simple estates',
            'Can take 15 mins',
          ],
          cta: {
            label: 'Basic Will',
            testId: 'basic-will',
            link: 'will.typeform-onboarding',
            utmParams: utmParams,
          },
        },
        {
          title: 'Bespoke Will',
          subheader: '£399',
          points: [
            'Advice is needed',
            'Completed over the phone or face to face',
            'For complex estates, foreign assets and business owners',
            'Trusts',
          ],
          cta: {
            label: 'Bespoke Will',
            testId: 'bespoke-will',
            link: 'will.typeform-onboarding',
            utmParams: utmParams,
          },
        },
      ],
    });

    const productSection = ref({
      products: [
        {
          title: 'Our basic and bespoke will services',
          svg: 'PenPaper',
        },
        { title: 'Getting a fast-tracked will', svg: 'ArrowTarget' },
        { title: 'Writing a mirror will', svg: 'TwoPages' },
        { title: 'Our will storage and update service', svg: 'ClosedSafe' },
      ],
    });
    const stepsSection = ref({
      title: 'Write your will in 3 simple steps',
      steps: [
        {
          svg: 'NumberOne',
          name: 'Answer a few simple questions',
          description:
            'We’ll help you write your will by asking questions to help work out what you want.',
        },
        {
          svg: 'NumberTwo',
          name: 'Complete your will',
          description:
            'A basic will is completed online and a bespoke will is completed with our in house team.',
        },
        {
          svg: 'NumberThree',
          name: 'Check and sign your will',
          description:
            'You can update your will whenever you like, for free in your first year, and only £10 a year after that.',
        },
      ],
    });

    return { heroSection, comparisonSection, productSection, stepsSection };
  },
};
</script>
