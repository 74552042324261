<template>
  <div class="row">
    <div class="column">
      <slot name="leftColumn" />
    </div>
    <div class="column">
      <slot name="rightColumn" />
    </div>
  </div>
</template>

<style scoped>
.row {
  @apply flex flex-wrap;
}

.column {
  @apply w-full md:flex-col md:flex md:justify-between md:w-1/2 gap-y-4 md:gap-y-6;
}
</style>
