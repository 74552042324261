<template>
  <section class="comparison">
    <div>
      <container :is-grid="false" class="justify-center">
        <h2 class="comparison__header" data-testid="will-comparison-header">
          {{ title }}
        </h2>
      </container>
    </div>
    <div class="lg:max-w-screen-lg mx-auto">
      <container :is-grid="false" class="justify-center">
        <div class="flex flex-col">
          <div class="flex justify-center w-full">
            <div class="comparison__table">
              <comparison
                v-for="column in table"
                :key="column.title"
                :points="column.points"
                :cta-label="column.cta.label"
                :cta-link="column.cta.link"
                :utm-params="column.cta.utmParams"
                :data-testid="column.cta.testId"
              >
                <template #title>{{ column.title }}</template>
                <template #price>{{ column.subheader }}</template>
              </comparison>
            </div>
          </div>
        </div>
      </container>
    </div>
  </section>
</template>
<script>
import Comparison from '@components/Comparison.vue';
import Container from '@components/Core/Container.vue';

export default {
  components: { Comparison, Container },

  props: {
    title: { type: String, default: '' },
    table: { type: Array },
  },

  setup() {},
};
</script>

<style scoped>
.comparison {
  @apply bg-ol-cream py-16 lg:py-32 text-ol-dark-ink-200;
}

.comparison__header {
  @apply font-bold text-2xl lg:text-4xl text-center mb-8 lg:mb-16;
}

.comparison__table {
  @apply w-screen px-5 md:px-10 md:w-auto flex flex-col md:flex-row gap-y-8 md:gap-y-0;
}
</style>
