<template>
  <div class="text-ol-dark-ink-200 w-full">
    <section class="md:text-center">
      <HeadingWithDescription
        class="mb-8"
        heading="Join the team"
        paragraph=" Explore our open roles for working remotely, from the office or
        somewhere in between."
      ></HeadingWithDescription>
    </section>
    <section v-if="parsedJobs.length">
      <h4 class="mb-8">Open Roles ({{ parsedJobs.length }})</h4>
      <div v-for="(roles, name) in groupedRoles">
        <h1 class="font-bold mb-6 text-2xl md:text-3xl">{{ name }}</h1>
        <JobCard v-for="role in roles" :role="role"></JobCard>
      </div>
    </section>
    <section v-else>
      <h3 class="text-xl md:text-2xl text-black font-medium text-center mt-8">
        There are no open roles right now. You can still send us an email to
        <a :href="`mailto:${companyEmail}`" class="btn text-ol-purple">{{
          companyEmail
        }}</a
        >.
      </h3>
    </section>
  </div>
</template>

<script>
import ButtonRounded from '@js/components/Button/ButtonRounded.vue';
import Arrow from '@js/components/Icon/Arrow.vue';
import JobCard from '@js/components/Brochure/careers/JobCard.vue';
import HeadingWithDescription from '@js/components/Brochure/HeadingWithDescription.vue';
import { groupBy } from '@js/utils/groupBy';

export default {
  components: {
    HeadingWithDescription,
    Arrow,
    ButtonRounded,
    JobCard,
  },
  props: {
    jobs: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const parsedJobs = props.jobs.map((job) => JSON.parse(job));

    const groupedRoles = groupBy(parsedJobs, 'departmentName');
    const companyEmail = window.config.contact_email;
    return { groupedRoles, parsedJobs, companyEmail };
  },
};
</script>

<style scoped>
section {
  @apply bg-transparent;
}
</style>
