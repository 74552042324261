<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.7914 3.46044C13.4061 2.53402 12.0938 2.53402 11.7084 3.46044L9.51148 8.74256L3.80899 9.19973C2.80884 9.27991 2.40329 10.528 3.1653 11.1808L7.51 14.9025L6.18262 20.4672C5.94982 21.4431 7.01155 22.2145 7.86781 21.6915L12.7499 18.7095L17.6321 21.6915C18.4883 22.2145 19.5501 21.4431 19.3173 20.4672L17.9899 14.9025L22.3346 11.1808C23.0966 10.528 22.691 9.27991 21.6909 9.19973L15.9884 8.74256L13.7914 3.46044Z"
      fill="#7457E8"
    />
  </svg>
</template>
