<script>
import axios from 'axios';

export default {
  name: 'ReverseImpersonateButton',
  props: {
    name: String,
    email: String,
  },
  methods: {
    reverseImpersonation() {
      if (confirm('Are you sure you want to stop impersonating?')) {
        this.stopImpersonating();
      }
    },
    async stopImpersonating() {
      try {
        let response = await axios.delete('/nova-api/impersonate');

        let redirect =
          window.guardianAngel?.pre_impersonation_url ||
          response?.data?.redirect ||
          null;

        if (redirect !== null) {
          window.location.href = redirect;
          return;
        }

        window.location.href = '/nova';
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<template>
  <div
    id="impersonating-banner"
    class="bg-red-100 h-[70px] md:h-[35px] w-7/10 md:w-auto fixed z-50 bottom-0 right-0 rounded-tl-xl"
    @click="reverseImpersonation"
  >
    <div class="items-center justify-content gap-x-6 py-2.5 px-3.5 flex h-full">
      You are impersonating {{ this.name }} - {{ this.email }}
      <button
        class="text-ol-cream font-ol-roobert hover:underline z-1"
        type="button"
      >
        Stop Impersonating
      </button>
    </div>
  </div>
</template>

<style scoped></style>
