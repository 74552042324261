<template>
  <div class="bg-white shadow-card rounded-xl p-6 lg:p-12">
    <slot />
  </div>
</template>

<style scoped>
h2 {
  @apply text-xl font-bold text-ol-dark leading-none mb-4 lg:text-3xl lg:mb-5;
}

ul li,
p,
td,
span {
  @apply text-base font-medium text-ol-dark-200 lg:text-lg;
}

svg {
  @apply w-5 h-5 lg:h-7 lg:w-7;
}
</style>
