import { createApp } from 'vue';
import { ZiggyVue } from 'ziggy-js';
import MainNav from '../components/MainNav/MainNav.vue';
import ReverseImpersonateButton from '../components/ReverseImpersonateButton.vue';
import store from './store';
import Carousel from '../components/Carousel.vue';
import BlockTestimonial from '../components/Block/BlockTestimonial.vue';
import AccordionPanel from '../components/AccordionPanel.vue';
import InView from './../modules/InView.vue';
import BlockPartner from '../components/Block/BlockPartner.vue';
import MonthlyPremiumVsAge from '../components/Graphic/MonthlyPremiumVsAge.vue';
import BlockCollapse from '../components/Block/BlockCollapse.vue';
import Container from '../components/Core/Container.vue';
import ListOption from '../components/ListOption.vue';
import Split from '../components/Split.vue';
import Cta from '../components/Button/Cta.vue';
import LogoOctopusLegacy from '../components/Icon/LogoOctopusLegacy.vue';
import KnockOnText from '../components/KnockOnText.vue';
import SamsStory from '../components/SamsStory.vue';
import BlockSimple from '../components/Block/BlockSimple.vue';
import BlockBasic from '../components/Block/BlockBasic.vue';
import OlInput from '../components/Form/Input.vue';
import OlInputWithPrompt from '../components/Form/InputWithPrompt.vue';
import OlButton from '../components/Form/Button.vue';
import BlockLine from '../components/Block/BlockLine.vue';
import BlockLarge from '../components/Block/BlockLarge.vue';
import OlPercentage from '../components/Graphic/Percentage.vue';
import SectionHeroImage from '../components/SectionHeroImage.vue';
import VerticalScrollAnimation from '../components/VerticalScrollAnimation.vue';
import VerticalScrollItem from '../components/VerticalScrollItem.vue';
import ImageLazyLoad from '../components/ImageLazyLoad.vue';
import Comparison from '../components/Comparison.vue';
import OLFooter from '@components/Footer/OLFooter.vue';
import SectionCta from '@components/Footer/SectionCta.vue';
import WillWriting from './WillWriting.vue';
import FreeWill from '@js/brochure/FreeWill.vue';
import Careers from '@brochure/Careers.vue';
import BrochureHero from '@components/Brochure/BrochureHero.vue';
import BrochureComparison from '@components/Brochure/BrochureComparison.vue';
import BrochureProduct from '@components/Brochure/BrochureProduct.vue';
import ButtonRounded from '@components/Button/ButtonRounded.vue';
import VerticalCarousel from '@components/Brochure/VerticalCarousel.vue';
import EditableField from '@js/support-hubs/components/EditableField.vue';
import CreatePasswordInput from '@components/Form/CreatePasswordInput.vue';
import '../../css/brochure/app.css';
import * as Sentry from '@sentry/vue';
import OLButton from '@components/Button/OLButton.vue';
import LegacyBoxHero from '@components/Brochure/fwig/LegacyBoxHero.vue';
import BoxStorySection from '@components/Brochure/fwig/BoxStorySection.vue';
import WhatsInTheBoxSection from '@components/Brochure/fwig/WhatsInTheBoxSection.vue';
import LegacyBoxBelowFold from '@components/Brochure/fwig/LegacyBoxBelowFold.vue';
import HowItWorksSection from '@components/Brochure/fwig/HowItWorksSection.vue';
import DiskIcon from '@components/Icon/DiskIcon.vue';
import OLBanner from '@components/OLBanner.vue';
import AdditionalServices from '@components/Probate/AdditionalServices.vue';
import BelowFold from '@components/Brochure/BelowFold.vue';
import ExpertsCard from '@components/Probate/ExpertsCard.vue';
import HeroSection from '@components/Probate/HeroSection.vue';
import NeedProbate from '@components/Probate/NeedProbate.vue';
import ScenarioCard from '@components/Brochure/ScenarioCard.vue';
import ScenarioSection from '@components/Brochure/ScenarioSection.vue';
import ServiceCard from '@components/Brochure/ServiceCard.vue';
import ServicesSection from '@components/Brochure/ServicesSection.vue';
import BoxWithIcon from '@components/Brochure/BoxWithIcon.vue';
import ContactCards from '@components/Brochure/Contact/ContactCards.vue';
import ContentSection from '@components/Brochure/Complaints/ContentSection.vue';
import WhatOurCustomersAreSayingSection from '@components/Brochure/fwig/WhatOurCustomersAreSayingSection.vue';

const app = createApp({
  components: {
    BrochureHero,
    BrochureComparison,
    BrochureProduct,
    BlockTestimonial,
    AccordionPanel,
    InView,
    BlockPartner,
    OLFooter,
    SectionCta,
    FreeWill,
    // V1
    MonthlyPremiumVsAge,
    MainNav,
    Carousel,
    Cta,
    BlockCollapse,
    ListOption,
    Container,
    Split,
    KnockOnText,
    LogoOctopusLegacy,
    SamsStory,
    BlockSimple,
    BlockBasic,
    EditableField,
    OlInput,
    OlButton,
    OlInputWithPrompt,
    BlockLine,
    BlockLarge,
    OlPercentage,
    SectionHeroImage,
    VerticalScrollAnimation,
    VerticalScrollItem,
    ImageLazyLoad,
    Comparison,
    ButtonRounded,
    ReverseImpersonateButton,
    WillWriting,
    VerticalCarousel,
    CreatePasswordInput,
    Careers,
    LegacyBoxHero,
    BoxStorySection,
    WhatsInTheBoxSection,
    LegacyBoxBelowFold,
    HowItWorksSection,
    WhatOurCustomersAreSayingSection,
    DiskIcon,
    OLBanner,
    HeroSection,
    BelowFold,
    NeedProbate,
    ServiceCard,
    ScenarioCard,
    ScenarioSection,
    ExpertsCard,
    AdditionalServices,
    ServicesSection,
    BoxWithIcon,
    ContactCards,
    ContentSection,
  },
});
Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
});
app.use(ZiggyVue);
app.use(store);
app.mixin({
  methods: {
    appRoute: window.route,
  },
});

// app.prototype.$route = (...args) => route(...args);

app.mount('#app');
